import "core-js/modules/es.parse-int.js";
import { KEYCODE } from "../../../enums/key-code";
import { EVENT_TYPE } from "../../../enums/event-type";
import { cleanStoreSearchPredictions } from "../store-context-search";
var MANUAL_FOCUS_CLASS = 'js-focus-prediction';
export function onPredictionsNavigation(event, searchPredictions) {
  var currentFocusId = parseInt(event.target.getAttribute('id'));
  var nextSuggest = currentFocusId + 1;
  var prevSuggest = currentFocusId - 1;
  switch (event.keyCode) {
    case KEYCODE.DOWN_ARROW:
      var downPredictionElem = searchPredictions.querySelector("li[id=\"".concat(nextSuggest, "\"]")) || searchPredictions.querySelector("li:first-child");
      var currentPredictionElem = searchPredictions.querySelector("li[id=\"".concat(currentFocusId, "\"]"));
      if (currentPredictionElem.classList.contains(MANUAL_FOCUS_CLASS)) {
        currentPredictionElem.classList.remove(MANUAL_FOCUS_CLASS);
      }
      downPredictionElem.focus();
      event.preventDefault();
      break;
    case KEYCODE.UP_ARROW:
      var upPredictionElem = searchPredictions.querySelector("li[id=\"".concat(prevSuggest, "\"]")) || searchPredictions.querySelector("li:last-child");
      upPredictionElem.focus();
      event.preventDefault();
      break;
    case KEYCODE.TAB:
      if (!searchPredictions.querySelector("li[id=\"".concat(nextSuggest, "\"]"))) {
        cleanStoreSearchPredictions();
      }
      break;
  }
}
export function onInputNavigation(event, searchPredictions) {
  var firstElemResultList = searchPredictions.querySelector("li");
  if (firstElemResultList) {
    switch (event.keyCode) {
      case KEYCODE.DOWN_ARROW:
        firstElemResultList.classList.remove(MANUAL_FOCUS_CLASS);
        firstElemResultList.focus();
        event.preventDefault();
        break;
      case KEYCODE.ENTER:
        firstElemResultList.focus();
        firstElemResultList.dispatchEvent(new CustomEvent(EVENT_TYPE.CLICK));
        break;
      case KEYCODE.TAB:
        cleanStoreSearchPredictions();
        break;
    }
  }
}