import "core-js/modules/es.array.concat.js";
var ID_SELECTOR = 'data-selectorid';
var STORE_CONTEXT_LAYER_ERROR = 'store-context-layer-error';
var STORE_SEARCH_ERROR = 'store-search-error';
export function insertErrorMessage(storeSearch, message) {
  var errorBlock = storeSearch.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_CONTEXT_LAYER_ERROR, "\"]"));
  errorBlock.innerHTML = message;
  var storeSearchErrorBlock = storeSearch.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_ERROR, "\"]"));
  storeSearchErrorBlock.classList.remove('kl-hidden');
  storeSearchErrorBlock.classList.add('kl-visible');
}
export function clearErrorMessage(storeSearch) {
  var storeSearchErrorBlock = storeSearch.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_ERROR, "\"]"));
  storeSearchErrorBlock.classList.remove('kl-visible');
  storeSearchErrorBlock.classList.add('kl-hidden');
}