export var DEFAULT_REQUEST_HEADER = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Cache': 'no-cache',
  'Access-Control-Allow-Origin': '*'
};
export var TEXT_HTML_REQUEST_HEADER = {
  'Accept': 'text/html',
  'Content-Type': 'text/html; charset=UTF-8',
  'Cache': 'no-cache',
  'Access-Control-Allow-Origin': '*'
};
export var STORE_HEADER_SERVICES_URL = '/store-header-module/services';