import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.date.to-json.js";
import { EVENT_TYPE } from "../../enums/event-type";
import { fetchStoresAroundRendering } from "../store-context-search/store-context-search";
import { customerContextCity, customerContextPostcode, ID_SELECTOR, STORE_CARD, STORE_LAYER_TITLE, STORE_SEARCH } from "../../customercontextlayer.src";
import { hitEventTms } from "integration-web-core--socle/js/assets/commons/_tms.js";
import { applyTrackingAndContextCookieOnStoreSearchBlock, integrateHtmlToStoreSearchBlock } from "../store-search-result/store-search-result";
import { retrieveCookieValueByName, retrieveCustomerContextCookieValue } from "../../../common/cookie-finder";
export function initStoreContextCard(storeContextLayerBlock) {
  var STORE_CHANGE_BUTTON = 'customer-context-change-button';
  var KL_HIDDEN_CLASS = 'kl-hidden';
  var storeSearchContainer = storeContextLayerBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH, "\"]"));
  var storeCardContainer = storeContextLayerBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_CARD, "\"]"));
  var storeChangeButton = storeContextLayerBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_CHANGE_BUTTON, "\"]"));
  var storeContextLayerTitle = storeContextLayerBlock.querySelector("".concat(STORE_LAYER_TITLE));
  var buttonContextUsedCookieValue = retrieveCookieValueByName("button_context_used");
  storeChangeButton.addEventListener(EVENT_TYPE.CLICK, function () {
    initStoreChangeButtonTracking(buttonContextUsedCookieValue);
    var customerContextCookie = retrieveCustomerContextCookieValue();
    // TODO remove check of latitude and longitude when cookie will be removed if values not present
    if (customerContextCookie && customerContextCookie.latitude && customerContextCookie.longitude) {
      var storeSearchBlock = storeContextLayerBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH, "\"]"));
      fetchStoresAroundRendering(customerContextCookie.latitude, customerContextCookie.longitude).then(function (result) {
        integrateHtmlToStoreSearchBlock(result, storeSearchBlock);
        customerContextPostcode = customerContextCookie.postcode;
        customerContextCity = customerContextCookie.city;
        applyTrackingAndContextCookieOnStoreSearchBlock(storeSearchBlock, customerContextCookie.latitude, customerContextCookie.longitude);
      });
    }
    storeCardContainer && storeCardContainer.classList.add(KL_HIDDEN_CLASS);
    storeSearchContainer && storeSearchContainer.classList.remove(KL_HIDDEN_CLASS);
    storeContextLayerTitle.innerHTML = storeheader.i18n.no_context_title;
    sendCdlEventOnSearchStepDisplay(buttonContextUsedCookieValue);
  });
}
function sendCDLEvent(eventContent) {
  hitEventTms(window, 'cdl_event', JSON.stringify(eventContent));
}
function initStoreChangeButtonTracking(funnel_name) {
  sendCDLEvent({
    'event_name': 'button.click',
    'event_data': {
      'feature_name': 'context_layer',
      'step_name': 'information content',
      'funnel_name': funnel_name || 'standalone',
      'button_name': 'Change store'
    }
  });
}
function sendCdlEventOnSearchStepDisplay(funnel_name) {
  sendCDLEvent({
    'event_name': 'step.display',
    'event_data': {
      'feature_name': 'context_layer',
      'step_name': 'store search',
      'funnel_name': funnel_name || 'standalone'
    }
  });
}