import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.starts-with.js";
export function retrieveCustomerContextCookieValue() {
  var customerContextCookie = retrieveCookieValueByName('customer_context');
  return customerContextCookie ? JSON.parse(customerContextCookie) : null;
}
export function retrieveCookieValueByName(name) {
  if (name) {
    var _document$cookie$spli;
    var cookie = (_document$cookie$spli = document.cookie.split("; ").find(function (row) {
      return row.startsWith("".concat(name, "="));
    })) === null || _document$cookie$spli === void 0 ? void 0 : _document$cookie$spli.split("=")[1];
    if (cookie) return decodeURIComponent(cookie);
  }
  return null;
}