import { DEFAULT_REQUEST_HEADER, STORE_HEADER_SERVICES_URL } from "../store-header-services-constants";
export function fetchTrackingCookieService(buttonLocationValue, successCallback, completeCallback) {
  lm.fetch({
    url: STORE_HEADER_SERVICES_URL + "/tracking/context-button-used-cookie",
    data: {
      value: buttonLocationValue
    },
    method: 'GET',
    credentials: 'same-origin',
    headers: DEFAULT_REQUEST_HEADER,
    success: successCallback,
    complete: completeCallback
  });
}