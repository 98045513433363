function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { EVENT_TYPE } from "../../../enums/event-type";
import { onPredictionSelection } from "../store-context-search";
export function renderStorePredictions(localities, searchPredictionsBlock) {
  var _this = this;
  var localitiesList = localities.map(function (locality, index) {
    var predictionDesc = locality.description;
    var listElemLocality = document.createElement('li');
    var focusPrediction = index === 0 ? ' js-focus-prediction' : '';
    listElemLocality.setAttribute('class', 'm-store-search-predictions__elem' + focusPrediction);
    listElemLocality.setAttribute('tabindex', "0");
    listElemLocality.setAttribute('id', index);
    listElemLocality.innerHTML = predictionDesc;
    listElemLocality.addEventListener(EVENT_TYPE.CLICK, function () {
      return onPredictionSelection(locality);
    });
    listElemLocality.addEventListener(EVENT_TYPE.KEYPRESS, function (event) {
      if (event.key === EVENT_TYPE.ENTER) {
        onPredictionSelection(locality);
      }
    }.bind(_this));
    return listElemLocality;
  });
  searchPredictionsBlock.replaceChildren.apply(searchPredictionsBlock, _toConsumableArray(localitiesList));
  searchPredictionsBlock.style.display = 'block';
}